import React from "react";
import "../styles/Testimonials.css";

function Testimonials() {
  return (
    <div className="Testimonials-container">
      <div className="Testimonials-content">
        <p>CHARTER BROKERAGE COMING SOON.</p>
        <p>WILL INCLUDE: VIDEO TESTAMONIALS, DETAILED REVIEWS AND MORE.</p>
        <p>COMING SOON...</p>
      </div>
      <div className="testimonials-color"></div>
    </div>
  );
}

export default Testimonials;
