import React from "react";
import "../styles/socials.css";

const Socials = () => {
  return (
    <div className="main">
      <div className="up">
        <a
          href="https://wa.me/46725312656"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card1">
            <svg
              viewBox="0 0 32 32"
              width="38px"
              height="38px"
              fillRule="nonzero"
              className="whatsapp"
            >
              <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
            </svg>
          </button>
        </a>

        <a
          href="https://www.linkedin.com/in/joar-lennermark-768a9525a/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card2">
            <svg
              viewBox="0 0 24 24"
              width="25px"
              height="25px"
              fillRule="nonzero"
              className="linkedin"
            >
              <path d="M22.225 0H1.77C.79 0 0 .79 0 1.77v20.46C0 23.21.79 24 1.77 24h20.455C23.21 24 24 23.21 24 22.23V1.77C24 .79 23.21 0 22.225 0zM7.077 20.44H3.59V9h3.487v11.44zM5.338 7.433a2.062 2.062 0 01-2.063-2.065 2.063 2.063 0 112.063 2.065zm15.11 13.01h-3.49v-5.569c0-1.328-.025-3.037-1.852-3.037-1.852 0-2.136 1.445-2.136 2.939v5.667H9.53V9h3.355v1.561h.046c.467-.883 1.61-1.813 3.315-1.813 3.548 0 4.202 2.328 4.202 5.363v6.33z"></path>
            </svg>
          </button>
        </a>
      </div>

      <div className="down">
        <a
          href="https://twitter.com/joarlennermark"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card3">
            <svg
              viewBox="0 0 48 48"
              width="70px"
              height="70px"
              className="twitter"
            >
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"></path>
            </svg>
          </button>
        </a>

        <a
          href="https://www.instagram.com/joarlennermark/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card4">
            <svg
              viewBox="0 0 32 32"
              width="37px"
              height="37px"
              className="instagram"
            >
              <path d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"></path>
            </svg>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Socials;
